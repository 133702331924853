<template>
  <div>
    <v-card flat class="card-datatable">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="11" sm="4">
            <v-text-field
              v-model="dataTable[$API_KEYS.SEARCH]"
              outlined
              dense
              label="Search by Teacher Name, Email"
              append-icon="mdi-magnify"
              type="text"
              color="#38227A"
              class="search-input"
              height="43px"
            >
            </v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="text-right">
            <v-btn
              color="#38227A"
              dark
              class="px-7 text-capitalize"
              height="45px"
              style="border-radius: 10px"
              @click="toggleAddRecordModal({ show: true, type: 'add' })"
            >
              <span>Add new record</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="table-div">
      <v-data-table
        :options.sync="dataOptions"
        fixed-header
        hide-default-footer
        :headers="dataTable.headers"
        :items="dataTable.items"
        :loading="dataTable.loading"
        :items-per-page="
          dataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint="0"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-center">
              <div>{{ props.item.student_name }}</div>
            </td>
            <td class="text-center">
              <div>
                {{ props.item.teacher_name }}-{{ props.item.grade_title }}
              </div>
            </td>
            <td class="text-center">
              <div>
                {{ props.item.video_title }}
              </div>
            </td>
            <td class="text-center">
              <div>
                {{ props.item.updated_date }}/{{ props.item.updated_time }}
              </div>
            </td>
            <td class="text-center">
              <div>
                {{ props.item.total_students }}
              </div>
            </td>
            <td class="text-center">
              <!--    <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="
                  toggleAddRecordModal({
                    show: true,
                    type: 'edit',
                    grade: props.item.grade,
                    video: props.item.video_sequence,
                  })
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      size="22"
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-chip>-->
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="
                  toggleDelete({
                    show: true,
                    grade: props.item.grade,
                    video: props.item.video_sequence,
                  })
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      size="22"
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-chip>
            </td>
          </tr>
        </template>
        <template v-slot:footer v-if="$vuetify.breakpoint.smAndUp">
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-6 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="justify-center justify-md-start d-flex px-5 tableHeader-text"
                >
                  <div class="alignSelf-center mr-3">Show</div>
                  <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                    <v-select
                      class=""
                      v-model="
                        dataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
                      "
                      :items="itemsPerPage"
                      hide-details
                      dense
                    ></v-select>
                  </div>

                  <div class="alignSelf-center">
                    {{ dataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER] }}
                    of
                    {{ dataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE] }}
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="4" md="4">
                <div>
                  <v-pagination
                    v-model="
                      dataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                    "
                    :length="
                      dataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                    "
                    :total-visible="5"
                    color="#D30024"
                    class="pagination"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <new-record-modal @reload="getDetail()"></new-record-modal>
    <delete-modal @reload="getDetail()"></delete-modal>
  </div>
</template>
<script>
import {
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
} from "@/constants/APIKeys";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
import { mapActions, mapGetters } from "vuex";
import { API_VIDEO_SHOWCASE_LIST } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import _ from "lodash";
export default {
  name: "VideoShowcaseTable",
  components: {
    NewRecordModal: () => import("./AddRecordModal/AddRecordModal"),
    DeleteModal: () =>
      import("../PeTeacherDashboard/VideoShowcaseDelete/VideoShowcaseDelete"),
  },
  data() {
    return {
      dataOptions: { sortBy: [], sortDesc: [] },
      dataTable: {
        headers: [
          {
            sortable: false,
            text: "Student Name",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: false,
            text: "Class",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
            value: "class",
          },
          {
            sortable: false,
            text: "Wizfit video",
            align: "center",
            value: "video",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: false,
            text: "Updated Date/Time",
            align: "center",
            value: "time",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: false,
            text: "No. of Students",
            align: "center",
            value: "no_of_student",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },

          {
            width: "17%",
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: false,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      itemsPerPage: [10, 15, 20, 30, 50, 100],
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
  },
  watch: {
    "dataTable.search": function () {
      this.debounceGetDetail();
    },
    "dataTable.page_info.page_number": function () {
      this.getDetail();
    },
    "dataTable.page_info.detail_count": function () {
      this.getDetail();
    },
    "dataOptions.sortBy": function () {
      if (this.dataOptions.sortBy.length !== 0) {
        this.dataTable[SORTING_KEY] = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getDetail();
      } else {
        this.dataTable[SORTING_KEY] = "";
        this.getDetail();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAddRecordModal: "peTeacher/toggleModal",
      toggleDelete: "peTeacher/toggleDeleteModal",
    }),
    getDetail() {
      this.dataTable[LOADING] = true;
      const successHandler = (res) => {
        this.dataTable[ITEMS] = res.data.wizfit_video_showcase_list;
        this.dataTable[PAGE_INFO][TOTAL_PAGE] = res.data[PAGE_INFO][TOTAL_PAGE];
        this.dataTable[PAGE_INFO][PAGE_NUMBER] =
          res.data[PAGE_INFO][PAGE_NUMBER];
        this.dataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res.data[PAGE_INFO][ITEMS_PER_PAGE];
        this.dataTable[LOADING] = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.dataTable[LOADING] = false;
      };
      let formData = {};
      formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
      formData["teacher_id"] = this.selectedCampaignData.organizationData.id;

      if (this.dataTable[PAGE_INFO][ITEMS_PER_PAGE] == 0) {
        formData[ITEMS_PER_PAGE] = 10;
      } else {
        formData[ITEMS_PER_PAGE] = this.dataTable[PAGE_INFO][ITEMS_PER_PAGE];
      }
      if (this.dataTable[PAGE_INFO][PAGE_NUMBER] == 0) {
        formData[PAGE_NUMBER] = 1;
      } else {
        formData[PAGE_NUMBER] = this.dataTable[PAGE_INFO][PAGE_NUMBER];
      }
      formData[SORTING_KEY] = this.dataTable[SORTING_KEY];
      Axios.request_GET(
        API_VIDEO_SHOWCASE_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    this.getDetail();
  },
  created() {
    this.debounceGetDetail = _.debounce(this.getDetail, 500);
  },
};
</script>
<style scoped>
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
}
.footer-card.v-card {
  max-height: 80px;
  font-family: Lato;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
  margin-bottom: 20px;
}
.alignSelf-center {
  align-self: center;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.card-datatable.v-card {
  max-height: 80px;
  margin-bottom: -2px;
  display: flex;
  box-shadow: 3px 5px 21px rgba(0, 0, 0, 0.12);
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.tableHeader-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
.v-icon.notranslate.mobile-menu {
  position: relative;
  bottom: 12px;
  right: -7px;
}
.text-capitalize.v-btn >>> span {
  letter-spacing: 0px;
  font-family: Lato;
  font-weight: 600;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  border-radius: 0px;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .table-div {
    overflow: scroll;
  }
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    min-width: 1200px;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tableHeader-text {
    font-size: 12px !important;
  }
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
